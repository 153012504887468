/*Common Styles For All Components*/
html {
  font-size: 62.5%;
}

html,
body {
  background-color: white;
  box-sizing: border-box;
  font-family: 'LoadUp Pro' !important;
  margin: 0 !important;
  padding: 0 !important;
}

body {
  font-size: 1.6rem;
  line-height: 1.625;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.css-7zmlgr {
  width: 100% !important
}

/* CDP, MDP Wordpress override */
#root .order-form h4 {
  margin: unset;
}

h1,
h2,
h3,
h4,
h6,
p,
a,
span {
  margin-bottom: 0 !important;
}

h5 {
  margin-bottom: 5px !important;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: #6C7587 !important;
}

h6 {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

h4 {
  color: #3F4242;
  font-size: 1.8rem !important;
  margin: 0 0 10px;
  line-height: 2.8rem !important;
}

input {
  font-family: 'LoadUp Pro';
}

label {
  font-weight: 600;
  font-size: 1.2rem;
  color: #919191;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.subtext {
  font-size: 1.2rem !important;
  line-height: 1.2rem !important;
  font-weight: 200 !important;
  display: inline-block;
}

.horizontal-site-spacing {
  max-width: 900px;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 5rem;
}

.sticky-container {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.position-fixed {
  position: fixed;
  max-width: 250px;
}
.fixed-card{
  box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  width: 100%;
  padding: 10px 20px 20px;
  background-color: #fff;
}

.btn-success {
  background: #8ac646;
  color: #fff;
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-opacity: 1;
  border-radius: 30px;
  border: none;
  margin-top: 20px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'LoadUp Pro', 'sans-serif';
  padding: 10px 25px !important;
  font-size: 1.5rem !important;
  max-height: 55px;
}

.btn-outline {
  background: transparent;
  border: 1px solid #8ac646;
  color: #8ac646;
  -webkit-text-fill-color: #8ac646;
  -webkit-opacity: 1;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.6rem;
  padding: 8px;
  opacity: 1;
}

.btn-outline:hover, .btn-outline:focus, .btn-outline:active {
  background: #FFFFFF;
}

.btn {
  opacity: 1;
}

.btn:active, .btn:hover, .btn:focus {
  opacity: 0.9;
}

.btn:disabled {
  color: #fff;
  background-color: #8ac646;
  border-color: #8ac646;
  opacity: 0.65 !important;
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-opacity: 1;
}

.entry-content p.text-danger, .text-danger {
  color: #DC2E48;
  font-weight: 700;
  letter-spacing: normal;
  font-size: .813em;
  line-height: inherit;
  clear: both;
  font-family: 'LoadUp Pro', sans-serif;
}

.border {
  border: 1px solid transparent;
}

.border-danger {
  border: 1px solid #DC2E48;
}

.border-success {
  border: 1px solid #8ac646;
}

//overwritting the Mui Papper

.accordionCard {
  border-radius: 3px;
  margin: 20px 0 !important;
  width: 100%;
  padding: 0 20px !important;
  background-color: #ffffff !important;
  .h4{
    font-weight: 600!important;
  }
}

.accordionCardActive {
  -webkit-box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.25) !important;
  opacity: 1;
}

.accordionCardDisabled {
  box-shadow: none !important;
  opacity: 0.65;
  margin: 0 auto !important;
}

.interiorCard {
  width: 100%;
  background: rgba(253, 253, 253, 0.9);
  border: 1px solid transparent;
  border-radius: 2px;
  margin: 5px auto;
  padding: 5px;
  opacity: 0.75;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    opacity: 1;
    background: #fefefe;
    box-shadow: rgb(254, 254, 254) 0 3px 3px 0;
    border: 1px solid #ccc;
  }
}

.interiorCardActive {
  border: 1px solid #8ac646 !important;
  background: #fefefe !important;
  opacity: 1;
  box-shadow: none !important;
  &:hover{
    box-shadow: rgb(240, 240, 240) 0 3px 3px 0 !important;
  }
}



@keyframes blink {
  50% {
    border-color: #7bae42;
  }
}

.blink-borders {
  /*or other element you want*/
  animation-delay: 0.2s;
  animation-name: blink;
  animation-duration: 0.3s;
  animation-timing-function: step-end;
  animation-iteration-count: 2;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.box-shadow {
  box-shadow: 0px -1px 5px -1px rgba(166, 166, 166, 1);
}

.invalid-error, .invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 1.2rem;
  color: #dc2e48;
  font-weight: 600;
}

.text-success {
  color: #8ac646
}
.content {
  .entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6, .entry-content p {
    &.text-success{
      color: #8ac646
    }
  }
}
.bg-white{
  background: #f7f7f7;
}
.bg-success {
  background: #8ac646;
}
.text-white {
  color: #fff;
  -webkit-text-fill-color: #fff;
  -webkit-opacity: 1;
}

.content {
  .entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6, .entry-content p {
    &.text-white {
      color: #fff;
    }
  }
}

.underline-text {
  border-bottom: solid 1px;
  display: inline;
  padding-bottom: 1px;
}

.ReactTable {
  width: 100%;
}

.ReactTable {
  border-radius: 0px;
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  border-bottom: 1px solid #ddd;
}

.ReactTable .rt-th {
  padding: 12px !important;
  font-size: 16px !important;
}

.ReactTable .rt-tbody .rt-td {
  font-size: 14px !important;
  padding: 10px !important;
}

.ReactTable .rt-tr-group:nth-child(odd) {
  background: #fafafa;
}

//Overwriting stripe
.baseStripe {
  height: 40px;
  font-size: 14px;
  width: 100%;
  flex-basis: 100%;
  max-width: 100%;
  box-sizing: border-box;
  flex: 0 0 auto;
  padding: 12px .5rem 4px;
  border: 1px solid #DDDDDD;
  color: #000
}

.baseStripeFocus {
  border: 1px solid #3F4242;
  outline: none;
}

.baseStripeError {
  border: 1px solid #DC2E48;
}

//the daypicker calendar and input styling
.SingleDatePicker, .SingleDatePickerInput__withBorder, .DateInput{
  width: 100%;
  border: none;
}
.DateInput_input__focused, .DateInput_input{
  width: 95%;
  border: 1px solid #ddd;
  padding: 7px 5px;
  font-size: 14px;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
  background: #8ac646;
  border: 1px double #8ac646;
}
.DayPickerKeyboardShortcuts_show__bottomRight{
border-right: 33px solid #8ac646;
}
.DayPicker_weekHeaders__horizontal{
  width: 300px;
  margin: 0 auto;
}
.CalendarMonth{
  padding: 0 0 0 4px!important;
}

//custom mobbile global styles
.hidden-sm {
  display: inline-block;
}

.visible-sm {
  display: none;
}


@media only screen and (min-width: 48em) {
  .hidden-sm {
    display: none;
  }
  .visible-sm {
    display: inline-block;
  }
}

/*Matching the theme styles. And Overwriting them*/
h2.sub_title {
  text-align: center;
  font-weight: 300;
  font-size: 22px;
  margin-top: -14px;
  padding: 0 30px;
}

p, .entry-content p {
  color: #3F4242;
  font-family: 'LoadUp Pro', 'sans-serif';
  font-size: 1.3rem;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 18px;
}

.entry-content ul .DayPicker_weekHeader_li {
  margin: 0;
  font-size: 1.2em;
  letter-spacing: 0;
}

.entry-title{
  font-weight: 900;
  letter-spacing: -0.3px;
  text-align: center;
  font-size: 51px;
  margin-top: 40px;
  padding: 0 30px;
}
.MuiExpansionPanelSummary-root-31, .MuiExpansionPanelDetails-root-59{
  padding: 0 0.5rem!important;
}

@media (max-width: 767px) {
  .accordionCard{
    padding: 0 !important;
  }
  .DateInput_input__focused, .DateInput_input{
    width: 100%;
  }
}

@media only screen and (max-width: 479px){
  .content .page h1.entry-title{
    margin-top: 65px;
  }
}

.new-order-summary {
  max-width: 270px;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: fixed;
  margin-left: 1em;
}
