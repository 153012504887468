.singleCard{
    max-width: 400px;
    margin: 0 auto 10px;
    border: none;
    border-radius: 4px;
    padding: 30px 20px;
    background: transparent;
    box-shadow: none;
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    input{
        text-align: center;
        &:focus, &:active{
            border: 1px solid #bbb;
        }
    }
}
.sub_Title{
    font-size: 1.8rem!important;
    max-width: 400px;
    font-weight: 300;
    margin: 10px auto 0 !important;
}
.link{
    color: #8ac646;
    border-bottom: 1px solid #8ac646;
    padding-bottom: 2px;
    -webkit-text-fill-color: #8ac646;
    -webkit-opacity: 1;
    &:hover{
        text-decoration: none;
        opacity: 0.75;
    }
}