.bottomFixed{
  position: fixed;
  width: 100vw;
  bottom: 0;
  background: #8ac646;
  color: #fff;
  -webkit-text-fill-color: #ffffff;
  -webkit-opacity: 1;
  padding-bottom: 10px;
}
.orderTitle{
  background-color: #8ac646;
  padding: 5px;
  color: #fff;
  -webkit-text-fill-color: #ffffff;
  -webkit-opacity: 1;
  font-weight: 900;
}