.input{
  height: 40px;
  padding: 4px 5px;
  font-size: 14px;
  width: 100%;
  flex-basis: 100%;
  max-width: 100%;
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: .5rem;
  padding-left: .5rem;
  border: 1px solid #ddd;
}

.input:focus,
.input:active {
  outline: none;
  border: 1px solid #bbb;
}
