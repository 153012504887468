.itemRow {
  border-top: 1px solid rgb(232, 232, 232);
  padding: 10px;
}

.label {
  text-transform: uppercase;
  font-size: 1.3rem !important;
  font-weight: 900 !important;
  line-height: 1.6rem !important;
  letter-spacing: 0.3px;
  margin-bottom: 10px !important;
}

.subText {
  font-size: 1.5rem !important;
  line-height: 1.5rem !important;
}

.img {
  max-width: 50px;
  max-height: 50px;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.remove {
  border: 1px solid;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 1rem !important;
  margin-top: 10px;
  padding: 2px 10px;
}

.warningBar {
  background: #F2F2F2;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1.1rem;
  font-weight: 700;
  a{
    appearance: none;
    color: #7bae42 !important;
    -webkit-text-fill-color: #7bae42!important;
    text-decoration: none;
  }
}