.btnOutline{
  margin-top: 20px;
  margin-bottom: 20px;
  background: #8ac646;
  color: #ffffff;
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-opacity: 1;
  border: 1px solid #8ac646;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.6rem;
  padding: 8px;
  opacity: 1;
  &:hover{
    opacity: 0.7;
  }
}
.btnOutline.disabled{
  opacity: 0.65;
  border-color: #595959;
  color: #595959;
  -webkit-text-fill-color: #595959;
  -webkit-opacity: 1;
  background: transparent;
}
.btnOutline.disabled:hover{
  background: transparent;
}

@media (max-width: 767px) {
  .btnOutline{
    margin: 20px auto;
  }
}