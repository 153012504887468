.card{
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.25);
  padding: 20px;
  h3.cardTitle{
    font-size: 2rem!important;
    font-weight: 900;
    margin-bottom: 15px!important;
  }
  p{
    margin-bottom: 5px !important;
  }
}

@media (max-width: 767px) {
  .card{
    padding: 20px 9px;
    margin-bottom: 20px;
  }
}