.toggleBtn{
  background: transparent;
  border: 1px solid #8ac646;
  color: #8ac646;
  -webkit-text-fill-color: #8ac646;
  -webkit-opacity: 1;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.2rem;
  padding: 8px;
  opacity: 0.75;
  &:hover, &:active, &:focus{
    opacity: 1;
    outline: none;
  }
}
.toggleBtnSuccess{
  opacity: 1;
  background: #8ac646;
  color: #ffffff;
  -webkit-text-fill-color: #fff;
}