.subSection{
  margin: 0 auto 10px;
}
.subtext{
  color: rgb(89, 89, 89);
  font-style: italic;
  font-size: 1.3rem;
  line-height: 1.3rem;
}

@media (max-width: 320px) {
  .mobileWidth{
    max-width: 300px;
    margin: 0 auto;
  }
}

.asapLabel {
  color: rgb(89, 89, 89);
  margin: 0.2rem 0 0;
  font-size: 1.3rem;
}