.box{
  width: 100%;
  background: rgba(253, 253, 253, 0.9);
  border: 1px solid #8ac646;
  border-radius: 2px;
  margin: 5px auto;
  min-height: 100px;
  max-height: 100px;
  padding: 5px;
  opacity: 0.65;
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.1);
  position: relative;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  box-sizing: border-box;
}
.box:after{
  content: "";
  display: block;
  padding-bottom: 100%;
}
.box:hover{
  opacity: 1;
  background: #fff;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(204,204,204,1);
  -moz-box-shadow: 0px 3px 10px 0px rgba(204,204,204,1);
  box-shadow: 0px 3px 10px 0px rgba(204,204,204,1);
  cursor: pointer;
}
.boxContent{
  position: absolute;
  z-index: 2;
  max-width: 100%;
  max-height: 100px;
  text-align: center;
  padding: 3px;
  left: 0;
  right: 0;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
}
.img{
  max-width: 50px;
  max-height: 50px;
  height: 100vh;
  width: 100vw;
  object-fit: contain;
  object-position: center center;
  margin: 0;
}
.label{
  text-transform: uppercase;
  font-size: 1rem!important;
  font-weight: 900!important;
  line-height: 1.3rem!important;
}

@media (max-width: 767px) {
  .box{
    max-width: 100%;
    min-height: 50px;
    max-height: 50px;
  }
  .img{
    max-width: 25px;
    max-height: 25px;
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle;
  }
  .boxContent{
    text-align: left;
  }
  .label{
    display: inline-block;
  }
}