.row{
  width: 100%;
}
.myItems{
  margin: 20px 0 0;
  padding-bottom: 10px;
}
.subSection, .entry-content .subSection{
  margin: 10px 0 !important;
}
.border{
  border: 1px solid transparent;
}
.borderDanger{
  border: 1px solid #DC2E48;
}
