.container {
  --load-up-green: #8ac646;
  --load-up-red: #E22E53;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.8em 0;
}

.zipLabel {
  margin: 0;
  text-align: center;
}

.zipInput {
  max-width: 10rem;
  text-align: center;
}
