.box {
  margin: 5px auto;
  color: #3B3F3F;
  -webkit-text-fill-color: #3B3F3F;
  -webkit-opacity: 1;
  border: 1px solid #3F4242;
  font-size: 1.2rem !important;
  width: 100%;
  padding: 8px;
  opacity: 0.75;
  cursor: pointer;
  border-radius: 2px;
  box-sizing: border-box;
}

.box:hover {
  opacity: 1;
  border: 1px solid #8ac646;
  color: #8ac646;
  -webkit-text-fill-color: #8ac646;
}

.borderSuccess {
  background: #8ac646 !important;
  color: #fff !important;
  -webkit-text-fill-color: #fff;
  -webkit-opacity: 1;
  border: 1px solid #8ac646;
  opacity: 1;

  p {
    color: #fff !important
  }

  &:hover {
    opacity: 0.7;

    .text-success {
      color: #ffffff;
      -webkit-text-fill-color: #fff;
    }

    p {
      color: #fff;
      -webkit-text-fill-color: #fff;
    }
  }
}