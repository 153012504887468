.btnSuccess{
  height: 100%;
  margin-top: 20px;
  margin-bottom: 35px;
  border-radius: 0!important;
  background: #8ac646;
  color: #fff;
  -webkit-text-fill-color: #fff;
  -webkit-opacity: 1;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'LoadUp Pro', 'sans-serif';
  padding: 10px 25px !important;
  font-size: 1.5rem !important;
  max-height: 55px;
  opacity: 1;
}
.btnSuccess:hover{
  opacity: 0.8;
}
.btnSuccessDisabled{
  color: #fff;
  -webkit-text-fill-color: #fff;
  background-color: #8ac646;
  border-color: #8ac646;
  opacity: 0.65;
  &:hover{
    opacity: 0.65;
  }
}