.itemCounterBtn{
  width: 30px;
  height: 30px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid #8ac646;
}
.itemCounterBtn svg{
  max-width: 10px;
  max-height: 10px;
  object-fit: contain;
  width: 100vw;
  height: 100vh;
  fill: #595959;
  -webkit-text-fill-color: #595959;
  -webkit-opacity: 1;
  color: #595959;
}
.itemCounterBtn:hover, .itemCounterBtn:focus, .itemCounterBtn:active{
  background: #fff;
}
.itemCounterBtn:hover svg, .itemCounterBtn:focus svg, .itemCounterBtn:active svg{
  fill: #000;
}

@media (max-width: 320px){
  .itemCounterBtn{
    width: 23px;
    height: 23px;
    svg{
      max-width: 8px;
      max-height: 8px;
      margin-bottom: 3px;
    }
  }
}