.buttons {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1em auto 0 auto;
  width: 60%;
  z-index: 0;
}

@media screen and (max-width: 768px) {
  .buttons {
    width: 100%;
  }
}
