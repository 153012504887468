 .btnPromo{
   background: #8ac646;
   color: #fff;
    -webkit-text-fill-color: #fff;
    -webkit-opacity: 1;
   border-radius: 30px;
   border: none;
   margin-top: 0px;
   font-weight: bold;
   text-transform: uppercase;
   font-family: 'LoadUp Pro', 'sans-serif';
   padding: 10px 25px !important;
   font-size: 1.3rem !important;
   height: 40px;
 }