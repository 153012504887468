.container {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 5px 5px 10px 10px;
  box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.25);
  border-bottom: 4px solid #8ac646;
  color: #222;
  padding: 1rem;
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
  .container {
    border-radius: 0;
    box-shadow: none;
  }
}

.mainText {
  display: flex;
  justify-content: center;
  padding-bottom: 14px;
  line-height: 18px;
}

.subtitleText {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 20px;
  font-size: 15px;
}

.textPrompt {
  padding-left: 1rem;
}

.phoneNumber {
  padding-left: 1rem;
}
