.label{
    margin-bottom: 10px !important;
    margin-top: 0px !important;
    font-size: 1.4rem !important;
    color: #3F4242;
}

.textArea {
  padding-top: 10px;
  padding-bottom: 10px;
  resize: none;
  font-size: 14px;
  border: 1px solid #ddd;
  &:focus, &:active{
    outline: none;
    border: 1px solid #595959;
  }
}