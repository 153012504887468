.container {
  --vertical-spacing: 0.8em;
  --load-up-green: #8ac646;
  --load-up-red: #E22E53;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #222;
  font-size: 2rem;
  padding: var(--vertical-spacing) 0;
}

.copy {
  font-size: var(--vertical-spacing);
  padding: var(--vertical-spacing) var(--vertical-spacing);
  line-height: 1.2em;
  max-width: 500px;
  text-align: center;
  width: 100%;
}

.header {
  font-weight: bold;
  margin-top: 1em;
  padding-bottom: var(--vertical-spacing);
}

@media screen and (max-width: 768px) {
  .header {
    margin-top: 0;
    text-align: center;
  }
}

.phoneNumber {
  color: var(--load-up-green);
}

.inServiceText {
  color: var(--load-up-green);
  font-weight: bold;
}

.outOfServiceText {
  color: var(--load-up-red);
  font-weight: bold;
}

.useZipButton {
  margin-top: var(--vertical-spacing) !important;
}
