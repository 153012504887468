.checkBox{
  margin: 30px auto 0;
  .checkArea{
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    border: 1px solid #919191;
    border-radius: 2px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    svg{
      stroke: #ffffff;
      margin-bottom: 9px;
    }
  }
  label{
    display: inline;
    margin-top: 0;
  }
}
