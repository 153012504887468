.btnSuccess{
    background: #8ac646;
    color: #fff;
    -webkit-text-fill-color: rgb(255, 255, 255);
    -webkit-opacity: 1;
    border-radius: 30px;
    border: none;
    margin-top: 20px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'LoadUp Pro', 'sans-serif';
    padding: 14px 50px !important;
    font-size: 2.0rem !important;
    max-height: 55px;
}
.icon{
    margin-right: 10px;
}